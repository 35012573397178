.table {
  border-collapse: collapse;

  .header {
    background: #ececec;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #000000;
    border: 1px solid #ececec;

    th {
      padding: 14px;
      cursor: pointer;

      span {
        margin-left: 4px;
      }
    }
  }

  .body {
    border-bottom: 1px solid #ececec;

    td {
      padding: 8px 16px;
      font-size: 14px;
      border-left: 1px solid #ececec;
      border-right: 1px solid #ececec;
    }
  }
}
