.overviewStats {
  background-color: #e5444b !important;
  color: #fff;

  &-title {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
  }

  &-value {
    font-weight: 350;
  }
}
